import React from 'react'
function TermsPage() {
  return (
    <>
      <section className="terms">
				<div className="terms__container">
					<div className="terms-wrapper">
						<div className="terms-info">
							<h1 className="terms-info__title">Terms of Use</h1>
							<p className="terms-info__desc">
								BEFORE ACCESSING OUR WEBSITE, READ THESE TERMS OF USE CAREFULLY AS THEY GOVERN YOUR USE OF THE WEBSITE AND CONTAIN IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES AND OBLIGATIONS.<br/>
								Welcome to Leselyx.ca. This website (the “Site“) is owned and operated by Rentals.ca Network, Inc. (“Rentals.ca“ or “we”).<br/>
								Access and use of the Site, and the information and the services thereon (collectively, the "Services") is provided to you by Rentals.ca on the condition that you accept the terms of this agreement (the "Agreement"). By accessing, browsing, registering on and/or otherwise using the <br/>Site, you agree:<br/>
								(1) to comply with all the terms of this Agreement;<br/>
								(2) to use the Site only for lawful and personal purposes:<br/>
								(3) not to use the Site in any manner that interferes with its security or normal operation or with any other user's use and enjoyment of the Site;<br/>
								(4) not access the Site by any means other than through the interface provided by Rentals.ca; and<br/>
								(5) pay any fees in accordance with the section entitled “Fees” below.<br/>
								If you do not agree with any of these terms , do not use or access the Site.<br/>
								If you have any questions about the terms, please contact us by using the contact information at the end of these terms of use.<br/>
								If you do not comply with this Agreement at any time, we reserve the right to cancel or terminate your access to the Site (or any part thereof) and/or your user account, if any. In Leselyx.ca's sole discretion and without prior notice or liability to you or any third party, we may suspend, discontinue, modify or alter any aspect of the Site including, but not limited to: (i) restricting the time the Site is available; (ii) restricting the amount of use permitted; and (iii) restricting or terminating any user's right to use the Site.<br/>
								Your only right with respect to any dissatisfaction with your use of the Site or any policies or practices by Leselyx.ca in providing the Site is to cease using the Site and terminate your subscription or registered user account by contacting.
							</p>
						</div>
						<div className="terms-info">
							<h2 className="terms-info__title">Your account</h2>
							<p className="terms-info__desc">
								In order to access certain Services provided on the Site, you will be required to establish a user account . To do so, you will need to provide us with certain information. You agree that the Account Information you provide is true, accurate, current and complete. If any of your Account Information changes, you must update it by using the appropriate update mechanism on the Site, if available. If you provide any information that is false, inaccurate, outdated or incomplete, or if Rentals.ca has reasonable grounds to suspect that any information you provide is false, inaccurate, outdated or incomplete, Leselyx.ca, in its sole discretion, may suspend or terminate your account and prevent you from using the Site (or any portion thereof) in the future.<br/>
								You are solely responsible for all usage or activity on your User Account including the use of your User Account by any person who uses your Account Information, with or without authorization, or who has access to any computer on which your account resides or is accessible. You may not sub-license, transfer, sell or assign your User Account, your Account Information and/or this Agreement to any third party without our prior written approval. Any attempt to do so will be null and void. If you have reason to believe that your User Account is no longer secure (for example, in the event of a loss, theft or unauthorized disclosure or use of your Account Information), you must promptly change the affected User Account Information by using the appropriate update mechanism on the Site.
							</p>
						</div>
						<div className="terms-info">
							<h2 className="terms-info__title">Fees</h2>
							<p className="terms-info__desc">
								Your “free subscription” is limited to a number of active listings. If you are a landlord or a property manager acting on behalf of a landlord and your account contains more than three (3) active listings in any given month, you must upgrade to a paid subscription by contacting our sales team at contact. Further, if you are a landlord or a property manager acting on behalf of a landlord and your account has more than one-hundred (100) units in your portfolio, you must pay a subscription to advertise your portfolio and will be introduced to Leselyx who handles all sales, support and billing for enterprise-level accounts on behalf of Leselyx.ca.
							</p>
						</div>
						<div className="terms-info">
							<h2 className="terms-info__title">Privacy policy</h2>
							<p className="terms-info__desc">
								Use of the Site is subject to the terms of our "Privacy Policy", which is hereby incorporated into and made part of this Agreement. By using the Site, you agree to be bound by the terms of our Privacy Policy.
							</p>
						</div>
						<div className="terms-info">
							<h2 className="terms-info__title">Amendments to this agreement</h2>
							<p className="terms-info__desc">
								From time to time, Leselyx.ca may amend this Agreement. We will post any revised version of this Agreement on the Site and encourage you to refer back to it on a regular basis.
							</p>
							<p className="terms-info__desc">
								YOUR CONTINUED ACCESS OF THE SITE WILL CONSTITUTE YOUR ACCEPTANCE OF ANY CHANGES OR REVISIONS TO THIS AGREEMENT. IF YOU DO NOT AGREE TO ANY CHANGES OR REVISIONS TO THE TERMS OF USE, STOP USING THE SITE AND DISCONTINUE YOUR USE OF THE SITE IMMEDIATELY.
							</p>
						</div>
						<div className="terms-info">
							<h2 className="terms-info__title">Monitoring the site and making amendments</h2>
							<p className="terms-info__desc">
								We do not constantly monitor the Site. However, we expressly reserve the right, in our sole discretion, to (1) monitor, refuse to publish, remove, delete, move or edit any content without notice, at any time for any reason; and (2) to delete from any listing or edit any falsehoods, inconsistencies, offensive material or any material that infringes third party rights or that is otherwise in breach of these Terms of Use.
							</p>
						</div>
						<div className="terms-info">
							<h2 className="terms-info__title">Our liability</h2>
							<p className="terms-info__desc">
								By using the Site, you expressly agree that Rentals.ca (1) will not be liable for any claims, actions or judgments arising out of or related to any content and (2) may monitor, refuse to publish, remove, delete, move or edit any content without notice at any time for any reason, without liability and without providing a refund. You further expressly agree that you are solely responsible for any and all content you submit to Leselyx.ca.
							</p>
							<p className="terms-info__desc">
								Leselyx.ca shall not be responsible for the accuracy, currency or completeness of the information that you provide for posting on the Site.
							</p>
						</div>
						<div className="terms-info">
							<h2 className="terms-info__title">Linking to or from this site</h2>
							<p className="terms-info__desc">
								The Site may provide links to third party websites for your convenience. The inclusion of these links does not imply that Leselyx.ca monitors or endorses these websites. Leselyx.ca accepts no responsibility for such websites. Running or displaying this Site or any information or material displayed on this Site in frames or through similar means on another website without our prior written permission is prohibited.
							</p>
						</div>
						<div className="terms-info">
							<h2 className="terms-info__title">Unsolicited submissions</h2>
							<p className="terms-info__desc">
								For any property listings, inquiries, or communications directly related to renting properties, please refrain from soliciting, poaching, spamming, or harassing our users. Such activities can result in permanent suspension of using our site and/or legal action.
							</p>
						</div>
						<div className="terms-info">
							<h2 className="terms-info__title">Third party apis</h2>
							<p className="terms-info__desc">
								From time to time, the Site may use one or more application programming interfaces obtained under license from a third party (an "API"). You agree that your use of any such APIs shall be limited exclusively to your use of the Site in accordance with the terms and conditions of this Agreement.
							</p>
						</div>
						<div className="terms-info">
							<h2 className="terms-info__title">Indemnification</h2>
							<p className="terms-info__desc">
								You agree to indemnify Leselyx.ca, and its affiliates, directors, officers, employees, agents, licensors, suppliers, content providers, and the like, and to defend and hold each of them harmless, from any and all claims and liabilities (including legal fees) which may arise from your unauthorized use of material obtained through the Site, or from your breach of this Agreement, or from any such acts through your use of the Site.
							</p>
						</div>
						<div className="terms-info">
							<h2 className="terms-info__title">Disclaimer of warranty</h2>
							<p className="terms-info__desc">
								ITS AFFILIATES HAVE NOT VERIFIED, CHECKED OR OTHERWISE SCREENED ANY LISTINGS ON THE SITE. FOR GREATER CERTAINTY, Leselyx.ca HAS NO AFFILIATION WITH ANY PROPERTY LISTED ON THE SITE, OR ANY LANDLORD, TENANT OR OTHER PERSON WHO MAY USE THE SITE.
								THE SITE AND THE SERVICES ARE PROVIDED "AS IS", "AS AVAILABLE" AND "WITH ALL FAULTS". Leselyx.ca AND ITS AFFILIATES HEREBY EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF ACCURACY, TIMELINESS, RELIABILITY, TITLE, MERCHANTABILITY, NON-INFRINGEMENT, FITNESS FOR A PARTICULAR PURPOSE, OR ANY OTHER WARRANTY, CONDITION, GUARANTEE OR REPRESENTATION, WHETHER ORAL, IN WRITING OR IN ELECTRONIC FORM, INCLUDING BUT NOT LIMITED TO THE PRODUCTS, OR SERVICES OFFERED OR SOLD ON OR THROUGH THE SITE OR ANY OTHER WEBSITE TO WHICH THE SITE MAY LINK, AS WELL AS THE ACCURACY OR COMPLETENESS OF ANY INFORMATION CONTAINED THEREIN OR PROVIDED BY THE WEBSITE.
								Leselyx.ca and its affiliates do not represent or warrant that access to the website will be uninterrupted or that there will be no failures, errors or omissions or loss of transmitted information, or that no viruses will be transmitted on the website. No oral or written statements by Leselyx.ca or its affiliates will create any warranty not expressly set forth herein.
							</p>
						</div>
						<div className="terms-info">
							<h2 className="terms-info__title">Limitation of liability</h2>
							<p className="terms-info__desc">
								IN NO EVENT SHALL Leselyx.ca OR ANY OF ITS AFFILIATES BE LIABLE FOR ANY GENERAL, INCIDENTAL, DIRECT, INDIRECT, PUNITIVE, EXEMPLARY, CONSEQUENTIAL OR OTHER DAMAGES WHATSOEVER (INCLUDING DAMAGES FOR LOSS OF PROFITS OR ANY OTHER PECUNIARY LOSS) IN CONNECTION WITH ANY CLAIM, LOSS, DAMAGE, ACTION, SUIT OR OTHER PROCEEDING ARISING UNDER OR OUT OF YOUR USE OF, RELIANCE UPON, ACCESS TO, OR EXPLOITATION OF ANY PRODUCTS OR SERVICES PURCHASED OR ACCESSED THROUGH THE SITE, EVEN IF Rentals.ca OR ITS AFFILIATES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, WHETHER THE ACTION IS BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE), INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OR OTHERWISE
								IN ANY EVENT, Leselyx.ca'S TOTAL MAXIMUM AGGREGATE LIABILITY UNDER THESE TERMS AND CONDITIONS OR ARISING FROM YOUR USE OF, RELIANCE UPON, ACCESS TO, OR EXPLOITATION OF THE SITE OR ANY SERVICES PURCHASED OR ACCESSED THROUGH THE SITE SHALL BE LIMITED TO THE PURCHASE PRICE ACTUALLY PAID BY YOU FOR SUCH SERVICES OR $20, WHICHEVER IS LESS.
								Some jurisdictions do not allow the disclaimer of certain warranties or the limitation or exclusion of liability for certain types of damages. Accordingly, some of the above disclaimers and limitations may not apply to you. In such jurisdictions, the aforementioned exclusions to liability are limited to the fullest extent permitted by law.
							</p>
						</div>
						<div className="terms-info">
							<h2 className="terms-info__title">Dispute resolution</h2>
							<p className="terms-info__desc">
								If any controversy, dispute, claim, question or difference arises with respect to the Site, to Services or Listings that you make or access through the Site, this Agreement, or its performance, enforcement, breach, termination or validity (a "Dispute"), the parties shall use their best efforts to settle the Dispute. To this end, they shall consult and negotiate with each other, in good faith, to reach a just and equitable solution satisfactory to all Parties. If the parties do not reach a solution to a Dispute within fifteen (15) business days following the first written notice of the Dispute by any party to the other, then upon written notice by any party to the other, the Dispute shall be submitted to confidential arbitration in Ottawa, Ontario, Canada, except that, to the extent that you have in any manner violated or threatened to violate the intellectual property rights of Rentals.ca or its affiliates. In such an instance, Leselyx.ca and its affiliates may seek injunctive or other appropriate relief in any provincial or federal court in the Province of Ontario, and you voluntarily consent to exclusive jurisdiction and venue in such courts. Arbitration under this agreement shall be conducted under the rules then prevailing of the National Arbitration Rules of the ADR Institute of Canada, Inc. in force at the date of commencement of the arbitration proceeding. The arbitrator's award shall be binding and may be entered as a judgment in any court of competent jurisdiction. To the fullest extent permitted by applicable law, no arbitration under this Agreement shall be joined to an arbitration involving any other party subject to this Agreement, whether through class arbitration proceedings or otherwise.
							</p>
						</div>
						<div className="terms-info">
							<h2 className="terms-info__title">Entire agreement</h2>
							<p className="terms-info__desc">
								This Agreement, together with the Privacy Policy, is the entire agreement between you and Leselyx.ca with respect to the Site, the Services, and your use of the same. In the event of a conflict between or among this Agreement and any other documents referenced in this Agreement, the conflict shall be resolved by assigning precedence to this Agreement, then to any documents referenced herein.
							</p>
						</div>
					</div>
				</div>
			</section>
    </>
  )
}

export { TermsPage }
