import React from "react";
import { NavLink} from 'react-router-dom'
function RegistrationPage() {
  return (
    <>
      <section className="registration">
				<div className="registration__container">
					<div className="registration__wrapper registration-img registration-info">
						<div className="registration-img-block">
							<img className="registration-img-block__item" src={require(`../assets/img/auth/reg-bg.png`)} alt="registration"/>
						</div>
						<div className="registration-info-block">
							<div className="registration-info-block__item block-item">
								<h1 className="block-item__title">Sign up</h1>
							</div>
							<div className="registration-info-block__form block-form">
								<form action="#" className="block-form-wrap">
									<div className="block-form-wrap__item">
										<label htmlFor="fname">Name*</label>
										<input type="text" name="fname" placeholder="Enter your name" required/>
									</div>
									<div className="block-form-wrap__item">
										<label htmlFor="email">Email*</label>
										<input type="email" name="email" placeholder="Enter your email" required/>
									</div>
									<div className="block-form-wrap__item">
										<label htmlFor="password">Password*</label>
										<input type="password" name="password" placeholder="Create your password" required/>
										<p>Must be at least 8 characters.</p>
									</div>
									<div className="block-form-wrap__item">
										<label htmlFor="password">Repeat Password*</label>
										<input type="password" name="password" placeholder="Repeat a password" required/>
										<p>Must be at least 8 characters.</p>
									</div>
									<button className="block-form-wrap__btn" type="submit">Create account</button>
								</form>
							</div>
							<div className="registration-info-block__reg block-reg">
								<p className="block-reg__desc">Already have an account?</p>
								<NavLink className="block-reg__link" to="/login">Log in</NavLink>
							</div>
						</div>
					</div>
				</div>
			</section>
    </>
 
  )
}

export  {RegistrationPage}