import React from 'react'

function PrivacyPolicyPage() {
  return (
    <>
      <section className='privacy'>
        <div className='privacy__container'>
          <div className='privacy-wrapper'>
            <div className='privacy-info'>
              <h1 className='privacy-info__title'>Privacy Policy</h1>
              <p className='privacy-info__desc'>
                The privacy of our clients and users is very important to the
                team at Leselyx.ca. We wish to always be open and upfront about
                what information we collect from you, a user of our website, and
                about how that information is used. Leselyx.ca. operates a
                website that allows people to list or locate apartments, houses
                or other real estate for rent online (Service), and makes it
                available to you, the user, subject to our Terms of Use. This
                Privacy Policy describes how the information you provide to us,
                or that we learn from you through the Service, is collected,
                used and disclosed. If you don't want your information
                collected, used or disclosed in the way outlined in this policy,
                please do not use the Service.
              </p>
            </div>
            <div className='privacy-info'>
              <h2 className='privacy-info__title'>The information we collect</h2>
              <p className='privacy-info__desc'>
                User-Provided Information: We may collect personal
                identification information from users in a variety of ways,
                including, but not limited to, when a user visits our site,
                registers for an account, places an order, subscribes to our
                newsletter, or fills out a form. We may also collect this
                information in connection with other activities, services,
                features or resources we make available on our Leselyx.ca. Users
                may be asked for, as appropriate, their name, email address,
                mailing address, phone number and credit card information. Users
                may, however, visit our Site anonymously, should they wish to
                not provide personal identification information. We will collect
                personal identification information from users only if they
                voluntarily submit such information to us. Users can always
                refuse to supply personal identification information, except
                that it may prevent them from engaging in certain activities
                related to the site. Non-Personal Identification Information: We
                may collect data, which is data from which individual
                identifiers have been removed, during your use of the site. For
                example, information about how you use the Service may be
                combined with information about how others use it, without any
                personal information being recorded in the resulting data. We
                use the personal information we collect in connection with
                providing you our services to detect and prevent fraudulent
                activity. Cookies: When you use the Service, we may send cookies
                to your computer’s hard drive. Cookies sometimes track
                information about your use of our site and Service, and provide
                Leselyx.ca, with information that may include the user’s browser
                type, computer or OS type, Internet Service Provider and
                technical information about how the user connects to our site.
                Automatically Collected Information: When you use Leselyx.ca,
                some information from your web browser or mobile device may be
                automatically stored. This automatically collected information
                includes your Internet Protocol address, the type of mobile
                device you use, web browser type, a record of websites that you
                visit before or after the Service, the pages you view, and the
                dates and times that you use the Service. We employ analytics
                companies to use tracking technologies that collect information
                about our users’ computers or mobile devices and their online
                activities. These companies analyze the data so we can better
                understand how our Service is being used. Third-Party Links: We
                may include links and buttons to third parties and links to
                external websites on our site, including links to property
                management companies’ websites, or other websites. These links
                and external sites and services might collect information about
                you and your internet activities through cookies – even if you
                do not click the button or link. Information collected through
                buttons is collected directly by these third parties without our
                participation. The manner of use of information collected by a
                third party in this fashion falls under that third party’s data
                collection, use and disclosure policies. Integrated Services:
                You might have the option to register with Rentals.ca Network,
                Inc. through a third party's service, such as a Facebook or
                Google account. If you agree to register in this manner, you
                authorize the Integrated Service to send us and allow us to
                store personal information and other information about you.
                Please review the privacy policies and terms and conditions of
                these integrated services. Location Information: Rentals.ca
                Network, Inc. includes features that allow us to access
                information about your location. On a web browser, you might
                have to enable settings that allow us to read your location,
                while on a mobile device, your settings could allow us to access
                your location automatically. These settings can be changed on
                your mobile device or web browser in order to disallow us from
                recording your location. We may also access your location using
                your IP address, through geo-fencing technology, or other
                available means.
              </p>
            </div>
            <div className='privacy-info'>
              <h2 className='privacy-info__title'>
                How your information is protected
              </h2>
              <p className='privacy-info__desc'>
                Leselyx.ca does not sell, trade, or rent Users' personal
                identification information to other parties. We may share
                generic aggregated demographic information not linked to any
                personal identification information regarding visitors and users
                with our business partners, trusted affiliates and advertisers
                for the purposes outlined in this Privacy Policy. We may use
                third-party service providers to help us operate our business
                and the Site or administer activities on our behalf, such as
                sending out newsletters or surveys. We may share your
                information with these third parties for those limited purposes,
                provided that you have given us your permission. Your Account
                and Password: You can always choose not to share personal
                information with Leselyx.ca, however we may not be able to
                provide you with all the features of our Service. You can ask us
                to delete your information from our active user databases by
                filling out the DSAR (Data Subject Access Request) form at the
                bottom of this page, in which case we will use commercially
                reasonable efforts to do so. Please note that while such
                deletions may be reflected in our active user databases, we may
                retain your personal information for a variety of other
                purposes, including backups and archiving, prevention of fraud
                and abuse, and analytics. To protect your privacy and security,
                we take reasonable steps to verify your identity before granting
                you access to your account for the Service. You are responsible
                for maintaining the secrecy of your unique password and account
                information at all times. Emails: You can unsubscribe from
                promotional and update emails from Leselyx.ca by following the
                instructions in the email. However, even if you do unsubscribe,
                you may still receive some administrative emails from us.
              </p>
            </div>
            <div className='privacy-info'>
              <h2 className='privacy-info__title'>
                Our Commitment to Data Security
              </h2>
              <p className='privacy-info__desc'>
                Sending data over the internet is never 100% secure. While we
                take reasonable steps to protect information you send to us via
                our Service, you do so at your own risk. Please note that we
                cannot completely guarantee that your information will never be
                accessed, disclosed, altered or destroyed by firewall or secure
                server software breach. If we learn of a security systems
                breach, we may try to notify you electronically so you can take
                action to protect your information. By using our Service or
                sending us your personal information, you agree that we can
                communicate with you electronically regarding security, privacy,
                and administrative issues relating to the Service. We may post a
                notice on our website if a security breach occurs, or we may
                email you a notification to the email address you provide to us.
                Depending on where you live, you may have a legal right to
                receive notice of a security breach in writing.
              </p>
            </div>
            <div className='privacy-info'>
              <h2 className='privacy-info__title'>
                A Notice To International Users
              </h2>
              <p className='privacy-info__desc'>
                Leselyx.ca's Service is hosted in Canada. For users who access
                our Service from outside of Canada, please note that you are
                transferring your personal data outside your home jurisdiction.
                By providing your personal data you consent to Rentals.ca
                Network, Inc.’s use of your personal data for the purposes laid
                out in this Privacy Policy.
              </p>
            </div>
            <div className='privacy-info'>
              <h2 className='privacy-info__title'>
                Changes To This Privacy Policy
              </h2>
              <p className='privacy-info__desc'>
                Leselyx.ca reserves the discretion to update this Privacy Policy
                at any time. When we do, we will revise the updated date at the
                bottom of this page.
              </p>
            </div>
            <div className='privacy-info'>
              <h2 className='privacy-info__title'>
                Your Acceptance Of These Terms
              </h2>
              <p className='privacy-info__desc'>
                By using Leselyx.ca, you signify your acceptance of this policy
                and the site’s Terms of Use. If you do not agree to this policy,
                please do not use our site. Your continued use of the Site
                following the posting of changes to this policy will be deemed
                your acceptance of those changes.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export { PrivacyPolicyPage }
