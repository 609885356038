import { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { HomePage } from './pages/HomePage'
import { LoginPage } from './pages/LoginPage'
import { RegistrationPage } from './pages/RegistrationPage'
import { RoomsPage } from './pages/RoomsPage'
import { RoomsDetailPage } from './pages/RoomsDetailPage'
import { TermsPage } from './pages/TermsPage'
import { PrivacyPolicyPage } from './pages/PrivacyPolicyPage'
import { NotfoundPage } from './pages/NotfoundPage'
import { Layout } from './components/Layout'

/**
 * In this project, I decided to use scss, so that I could easily type it
 * and have additional features like file separation and the use of constants
 */
import './assets/scss/style.scss'

/**
 * The NotfoundPage works as a 404 page, if the path is incorrect then it will not load this page.
 */
function App() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path='privacy-policy' element={<PrivacyPolicyPage />} />
          <Route path='terms' element={<TermsPage />} />
          <Route path='login' element={<LoginPage />} />
          <Route path='registration' element={<RegistrationPage />} />
          <Route path='rooms' element={<RoomsPage />} />
          <Route path='rooms/:roomsId' element={<RoomsDetailPage />} />
          <Route path='*' element={<NotfoundPage />} />
        </Route>
      </Routes>
    </>
  )
}

export default App
