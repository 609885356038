import React, { useState } from 'react'
import { Range, getTrackBackground } from 'react-range'
import { RoomsCard } from '../components/RoomsCard'
import roomsData from '../data/roomsData.json'
import { ReactComponent as Filtering } from '../assets/img/icon/filtering.svg'
import { ReactComponent as Close } from '../assets/img/icon/close.svg'
function RoomsPage() {
  const [toggleAside, setToggleAside] = useState(false)

  const handleOpenAside = () => {
    setToggleAside(true)
    document.body.classList.add('hiden')
  }
  const handleCloseAside = () => {
    setToggleAside(false)
    document.body.classList.remove('hiden')
  }

  const STEP = 1
  const MIN = 0
  const MAX = 1500
  const [values, setValues] = React.useState([0, 1500])
  return (
    <>
      <div className='rooms-layout'>
        <div className='rooms-layout__container'>
          <section
            className={`rooms-layout__sidebar sidebar ${
              toggleAside ? 'open' : ''
            }`}
          >
            <button className='sidebar-close-btn' onClick={handleCloseAside}>
              <Close />
            </button>
            <form action='#'>
              <div className='sidebar-wrapper'>
                <div className='sidebar-location'>
                  <h4 className='sidebar-location__title'>Location</h4>
                  <div className='checkbox sidebar-location__checkbox'>
                    <div className='checkbox'>
                      <input
                        id='l_1'
                        className='checkbox__input'
                        type='checkbox'
                        name='location'
                      />
                      <label htmlFor='l_1' className='checkbox__label'>
                        <span className='checkbox__text'>Toronto</span>
                      </label>
                    </div>
                    <div className='checkbox'>
                      <input
                        id='l_2'
                        className='checkbox__input'
                        type='checkbox'
                        name='location'
                      />
                      <label htmlFor='l_2' className='checkbox__label'>
                        <span className='checkbox__text'>Mississagua</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className='sidebar-budget'>
                  <h4>Budget</h4>
                  <div className='element'>
                    <div className='price-input'>
                      <div className='field'>
                        <input
                          name='min'
                          type='number'
                          className='input-min'
                          placeholder='Min'
                        />
                      </div>
                      <div className='field'>
                        <input
                          type='number'
                          className='input-max'
                          placeholder='Max'
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Range
                        values={values}
                        step={STEP}
                        min={MIN}
                        max={MAX}
                        onChange={(values) => setValues(values)}
                        renderTrack={({ props, children }) => (
                          <div
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            style={{
                              ...props.style,
                              height: '36px',
                              display: 'flex',
                              width: '100%',
                            }}
                          >
                            <div
                              ref={props.ref}
                              style={{
                                height: '5px',
                                width: '100%',
                                borderRadius: '4px',
                                background: getTrackBackground({
                                  values,
                                  colors: ['#EAECF0', '#0092C0', '#EAECF0'],
                                  min: MIN,
                                  max: MAX,
                                }),
                                alignSelf: 'center',
                              }}
                            >
                              {children}
                            </div>
                          </div>
                        )}
                        renderThumb={({ index, props, isDragged }) => (
                          <div
                            {...props}
                            key={props.key}
                            className='range-slider-thumb'
                            style={{
                              ...props.style,
                              height: '24px',
                              width: '24px',
                              borderRadius: '50%',
                              backgroundColor: '#FFF',
                              border: '1px solid #0092C0',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <div
                              style={{
                                position: 'absolute',
                                bottom: '-34px',
                                color: '#aeaeae',
                                fontSize: '.75rem',
                                fontWeight: '600',
                                padding: ' 8px 12px',
                                borderRadius: '8px',
                                backgroundColor: '#fff',
                                filter:
                                  'drop-shadow(rgba(16, 24, 40, 0.1) 0px 5px 3px)',
                              }}
                            >
                              {values[index]}
                            </div>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className='sidebar-dates' id='checkin-container'>
                  <h4>Dates</h4>
                  <div className='sidebar-dates__date dates-date'>
                    <div className='dates-date__wrapper'>
                      <input
                        type='text'
                        className='search-filter-form__item'
                        id='checkin'
                        data-datepicker=''
                        placeholder='From'
                      />
                    </div>
                    <div className='dates-date__wrapper'>
                      <input
                        type='text'
                        className='search-filter-form__item'
                        id='checkout'
                        data-datepicker=''
                        placeholder='To'
                      />
                    </div>
                  </div>
                </div>
                <div className='sidebar-type'>
                  <h4>Room Type</h4>
                  <div className='checkbox sidebar-type__checkbox'>
                    <div className='checkbox'>
                      <input
                        id='t_1'
                        className='checkbox__input'
                        type='checkbox'
                        name='type'
                      />
                      <label htmlFor='t_1' className='checkbox__label'>
                        <span className='checkbox__text'>Studio</span>
                      </label>
                    </div>
                    <div className='checkbox'>
                      <input
                        id='t_2'
                        className='checkbox__input'
                        type='checkbox'
                        name='type'
                      />
                      <label htmlFor='t_2' className='checkbox__label'>
                        <span className='checkbox__text'>Apartment</span>
                      </label>
                    </div>
                    <div className='checkbox'>
                      <input
                        id='t_3'
                        className='checkbox__input'
                        type='checkbox'
                        name='type'
                      />
                      <label htmlFor='t_3' className='checkbox__label'>
                        <span className='checkbox__text'>Private Room</span>
                      </label>
                    </div>
                  </div>
                  <div className='sidebar-type-showmore'>
                    <input
                      id='read-more-check-1'
                      type='checkbox'
                      className='read-more-check'
                    />
                    <div className='read-more'>
                      <div className='checkbox sidebar-type__checkbox'>
                        <div className='checkbox'>
                          <input
                            id='t_1'
                            className='checkbox__input'
                            type='checkbox'
                            name='type'
                          />
                          <label htmlFor='t_1' className='checkbox__label'>
                            <span className='checkbox__text'>Studio</span>
                          </label>
                        </div>
                        <div className='checkbox'>
                          <input
                            id='t_2'
                            className='checkbox__input'
                            type='checkbox'
                            name='type'
                          />
                          <label htmlFor='t_2' className='checkbox__label'>
                            <span className='checkbox__text'>Apartment</span>
                          </label>
                        </div>
                        <div className='checkbox'>
                          <input
                            id='t_3'
                            className='checkbox__input'
                            type='checkbox'
                            name='type'
                          />
                          <label htmlFor='t_3' className='checkbox__label'>
                            <span className='checkbox__text'>Private Room</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <label
                      htmlFor='read-more-check-1'
                      className='read-more-label'
                    ></label>
                  </div>
                </div>
                <div className='sidebar-amenities'>
                  <h4>Amenities</h4>
                  <div className='checkbox sidebar-type__checkbox'>
                    <div className='checkbox'>
                      <input
                        id='a_1'
                        className='checkbox__input'
                        type='checkbox'
                        name='type'
                      />
                      <label htmlFor='a_1' className='checkbox__label'>
                        <span className='checkbox__text'>Heating</span>
                      </label>
                    </div>
                    <div className='checkbox'>
                      <input
                        id='a_2'
                        className='checkbox__input'
                        type='checkbox'
                        name='type'
                      />
                      <label htmlFor='a_2' className='checkbox__label'>
                        <span className='checkbox__text'>Dryer</span>
                      </label>
                    </div>
                    <div className='checkbox'>
                      <input
                        id='a_3'
                        className='checkbox__input'
                        type='checkbox'
                        name='type'
                      />
                      <label htmlFor='a_3' className='checkbox__label'>
                        <span className='checkbox__text'>Air conditioning</span>
                      </label>
                    </div>
                    <div className='checkbox'>
                      <input
                        id='a_4'
                        className='checkbox__input'
                        type='checkbox'
                        name='type'
                      />
                      <label htmlFor='a_4' className='checkbox__label'>
                        <span className='checkbox__text'>Washing Machine</span>
                      </label>
                    </div>
                    <div className='checkbox'>
                      <input
                        id='a_5'
                        className='checkbox__input'
                        type='checkbox'
                        name='type'
                      />
                      <label htmlFor='a_5' className='checkbox__label'>
                        <span className='checkbox__text'>Dishwasher</span>
                      </label>
                    </div>
                    <div className='checkbox'>
                      <input
                        id='a_6'
                        className='checkbox__input'
                        type='checkbox'
                        name='type'
                      />
                      <label htmlFor='a_6' className='checkbox__label'>
                        <span className='checkbox__text'>Private bathroom</span>
                      </label>
                    </div>
                  </div>
                  <div className='sidebar-type-showmore'>
                    <input
                      id='read-more-check-2'
                      type='checkbox'
                      className='read-more-check'
                    />
                    <div className='read-more'>
                      <div className='checkbox sidebar-type__checkbox'>
                        <div className='checkbox'>
                          <input
                            id='a_7'
                            className='checkbox__input'
                            type='checkbox'
                            name='type'
                          />
                          <label htmlFor='a_7' className='checkbox__label'>
                            <span className='checkbox__text'>Studio</span>
                          </label>
                        </div>
                        <div className='checkbox'>
                          <input
                            id='a_8'
                            className='checkbox__input'
                            type='checkbox'
                            name='type'
                          />
                          <label htmlFor='a_8' className='checkbox__label'>
                            <span className='checkbox__text'>Dryer</span>
                          </label>
                        </div>
                        <div className='checkbox'>
                          <input
                            id='a_9'
                            className='checkbox__input'
                            type='checkbox'
                            name='type'
                          />
                          <label htmlFor='a_9' className='checkbox__label'>
                            <span className='checkbox__text'>
                              Air conditioning
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <label
                      htmlFor='read-more-check-2'
                      className='read-more-label'
                    ></label>
                  </div>
                </div>
              </div>
            </form>
          </section>
          <section className='rooms-layout__content content'>
            <div className='content-map'>
              <img src={require(`../assets/img/rooms/map.png`)} alt='map' />
            </div>
            <div className='content-filter'>
              <div className='content-filter-count'>
                <button className='filter-btn' onClick={handleOpenAside}>
                  <Filtering />
                </button>
                <p className='content-filter-count__item'>
                  <span>{roomsData.length}</span> Rentals
                </p>
              </div>
              <div className='content-filter-sort'>
                <select className='select__title' name='sort'>
                  <option defaultValue='sort'>Sort by</option>
                  <option defaultValue='newest'>Newest</option>
                  <option defaultValue='last'>Last</option>
                </select>
                <div className='content-filter-sort__btn sort-btn'>
                  <button type='button' className='sort-btn__column active'>
                    <img
                      src={require(`../assets/img/rooms/vector.svg`).default}
                      alt=''
                    />
                  </button>
                  <button type='button' className='sort-btn__flex'>
                    <img
                      src={require(`../assets/img/rooms/list.svg`).default}
                      alt=''
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className='content-rooms rental-rooms'>
              {roomsData.map((item, idx) => {
                return (
                  <RoomsCard
                    key={idx}
                    page={item.id}
                    img={item.img}
                    title={item.title}
                    price={item.price}
                    address={item.address}
                    beds={item.beds}
                    baths={item.baths}
                    ft={item.ft}
                  />
                )
              })}
            </div>
          </section>
        </div>
      </div>
    </>
  )
}

export { RoomsPage }
