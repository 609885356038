import React from 'react'
import { NavLink } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

function Datapicker({ startDate, setStart, placeholder, arrow = true }) {
  return (
    <div className='datepicker-custom-con'>
      <img
        src={
          require(`../../assets/img/home/welcome/font-icon/date.svg`).default
        }
        alt='date'
      />
      <DatePicker
        className='datepicker-custom'
        placeholderText={placeholder}
        selected={startDate}
        onChange={(date) => setStart(date)}
      />
      {arrow ? (
        <div className='datepicker-custom-arrow'>
          <img
            className='icon-arrow'
            src={
              require(`../../assets/img/home/welcome/font-icon/arrow-down.svg`)
                .default
            }
            alt='arrow'
          />
        </div>
      ) : null}
    </div>
  )
}

export { Datapicker }
