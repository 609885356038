import React from 'react'
import { NavLink } from 'react-router-dom'


function RoomsCard({page, img, title, price, address, beds, baths, ft}) {
  return (
    <NavLink to={`/rooms/${page}`} className='rental-rooms-product'>
      <div className='rental-rooms-product__img'>
        <img src={require(`../assets/img/rooms/${img[0]}`)} alt='product' />
      </div>
      <div className='rental-rooms-product__info product-info'>
        <div className='product-info__desc desc-item'>
          <div className='desc-item-location'>
            <h3 className='desc-item-location__title'>
              {title}
            </h3>
            <span>
              <i>
                <img
                  src={
                    require(`../assets/img/home/welcome/font-icon/location.svg`)
                      .default
                  }
                  alt='location'
                />
              </i>
              <p>{address}</p>
            </span>
          </div>
          <div className='desc-item-price'>
            <h3 className='desc-item-price__title'>
              $<span>{price}</span>
            </h3>
            <p>/ month</p>
          </div>
        </div>
        <div className='product-info__properties properties-item'>
          <div className='properties-item-block'>
            <i>
              <img
                src={
                  require(`../assets/img/home/welcome/font-icon/arrow-right-circle.svg`)
                    .default
                }
                alt=''
              />
            </i>
            <p>
              <span>{beds}</span> Beds
            </p>
          </div>
          <div className='properties-item-block'>
            <i>
              <img
                src={
                  require(`../assets/img/home/welcome/font-icon/arrow-right-circle.svg`)
                    .default
                }
                alt=''
              />
            </i>
            <p>
              <span>{baths}</span> Baths
            </p>
          </div>
          <div className='properties-item-block'>
            <i>
              <img
                src={
                  require(`../assets/img/home/welcome/font-icon/arrow-right-circle.svg`)
                    .default
                }
                alt=''
              />
            </i>
            <p>
              <span>{ft}</span> sqft
            </p>
          </div>
        </div>
      </div>
    </NavLink>
  )
}

export { RoomsCard }
