import React, { useState } from 'react'
import { RoomsCard } from '../components/RoomsCard'
import roomsData from '../data/roomsData.json'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Datapicker } from '../components/inputs/Datapicker'
function HomePage() {
  const [startDate, setStartDate] = useState('')
  return (
    <>
      <section className='welcome'>
        <div className='welcome__container'>
          <div className='hero'>
            <div className='hero__info'>
              <h1 className='hero__info-title'>
                Experience Luxury Living at Our Rental Properties.
              </h1>
              <p className='hero__info-desc'>
                Experience the best luxury living with our premium rental
                properties, offering top amenities and prime locations.
              </p>
            </div>
            <div className='hero__img'>
              <img
                className='hero__img-item'
                src={require(`../assets/img/home/welcome/hero.png`)}
                alt='hero'
              />
            </div>
          </div>
          <div className='search'>
            <h4 className='search__title'>Search for available rooms</h4>
            <div className='search-filter'>
              <form action='#' className='search-filter-form'>
                <select className='select__title'>
                  <option defaultValue='1'>Location</option>
                  <option defaultValue='canada'>Canada</option>
                </select>
                <div className='search-filter-form__block'>
                  <i>
                    <img
                      src={
                        require(`../assets/img/home/welcome/font-icon/euro-icon.svg`)
                          .default
                      }
                      alt='euro'
                    />
                  </i>
                  <input
                    type='number'
                    className='search-filter-form__item'
                    name='balance'
                    placeholder='Budget'
                  />
                </div>

                <Datapicker
                  placeholder={'Check In'}
                  arrow={false}
                  startDate={startDate}
                  setStart={setStartDate}
                />

                <button className='__btn' type='submit'>
                  Search
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section className='about'>
        <div className='about__container'>
          <div className='about-block'>
            <h4 className='about-block__heading'>About Us</h4>
            <h2 className='about-block__title'>leselyx Rental Solutions</h2>
            <p className='about-block__desc'>
              At our site, we offer for all your housing needs. Our mission is
              to make the rental process as easy and stress-free as possible for
              our clients.
            </p>
            <p className='about-block__desc'>
              We have a wide range of properties available for rent, including
              houses, apartments and rooms, in various locations to suit your
              lifestyle and budget.
            </p>
          </div>
          <div className='about-awards'>
            <div className='about-awards-block'>
              <span>
                <i>
                  <img
                    src={
                      require(`../assets/img/home/welcome/font-icon/smile.svg`)
                        .default
                    }
                    alt=''
                  />
                </i>
              </span>
              <p className='about-awards-block__value'>
                <span>98</span>%
              </p>
              <h4 className='about-awards-block__title'>Positive Feedback</h4>
            </div>
            <div className='about-awards-block'>
              <span>
                <i>
                  <img
                    src={
                      require(`../assets/img/home/welcome/font-icon/home.svg`)
                        .default
                    }
                    alt=''
                  />
                </i>
              </span>
              <p className='about-awards-block__value'>
                <span>15</span>+
              </p>
              <h4 className='about-awards-block__title'>Years of Experience</h4>
            </div>
          </div>
        </div>
      </section>
      <section className='rental'>
        <div className='rental__container'>
          <div className='rental-block'>
            <h4 className='rental-block__heading'>featured listing</h4>
            <h2 className='rental-block__title'>premium rental properties</h2>
            <form action='#' className='rental-block-form'>
              <div className='rental-block-form__item'>
                <label>Location</label>
                <select className='select__title' name='location'>
                  <option defaultValue='1'>Choose location...</option>
                  <option defaultValue='canada'>Canada</option>
                </select>
              </div>
              <div className='rental-block-form__item'>
                <label>Property Type</label>
                <select className='select__title' name='type'>
                  <option defaultValue='1'>Choose type...</option>
                  <option defaultValue='canada'>Canada</option>
                </select>
              </div>
              <div className='rental-block-form__item'>
                <label>Beds & Baths</label>
                <select className='select__title' name='beds'>
                  <option defaultValue='1'>Choose beds & baths...</option>
                  <option defaultValue='canada'>Canada</option>
                </select>
              </div>
              <div className='rental-block-form__item'>
                <label>Price</label>
                <select className='select__title' name='price'>
                  <option defaultValue='1'>Choose price...</option>
                  <option defaultValue='canada'>Canada</option>
                </select>
              </div>
              <div className='rental-block-form__item'>
                <label>Rent Period</label>
                <select className='select__title' name='period'>
                  <option defaultValue='1'>Choose period...</option>
                  <option defaultValue='canada'>Canada</option>
                </select>
              </div>
              <button className='__btn' type='submit'>
                Search
              </button>
            </form>
          </div>
          <div className='rental-rooms'>
            {roomsData.slice(0, 4).map((item, idx) => {
              return (
                <RoomsCard
                  key={idx}
                  page={item.id}
                  img={item.img}
                  title={item.title}
                  price={item.price}
                  address={item.address}
                  beds={item.beds}
                  baths={item.baths}
                  ft={item.ft}
                />
              )
            })}
          </div>
        </div>
      </section>
      <section className='experience'>
        <div className='experience__container'>
          <div className='experience-header'>
            <h4 className='experience-header__heading'>our value</h4>
            <h2 className='experience-header__title'>
              Experience the benefits of choosing US
            </h2>
          </div>
          <div className='experience-cards'>
            <div className='experience-card_space'></div>
            <div className='experience-card experience-card_active'>
              <i>
                <img
                  src={
                    require(`../assets/img/home/welcome/font-icon/home-white.svg`)
                      .default
                  }
                  alt='home'
                />
              </i>
              <h3 className='experience-card__title'>trusted service</h3>
              <p className='experience-card__desc'>
                Enim odio ut nulla malesuada eu. Vel donec porta praesent
                libero. Lobortis eu pellentesque viverra fringilla. Gravida
                congue integer ultrices nullam nisi in elit vel vitae.
              </p>
            </div>
            <div className='experience-card'>
              <i>
                <img
                  src={
                    require(`../assets/img/home/welcome/font-icon/home-blue.svg`)
                      .default
                  }
                  alt='home'
                />
              </i>
              <h3 className='experience-card__title'>Effortless Rentals</h3>
              <p className='experience-card__desc'>
                Enim odio ut nulla malesuada eu. Vel donec porta praesent
                libero. Lobortis eu pellentesque viverra fringilla. Gravida
                congue integer ultrices nullam nisi in elit vel vitae.
              </p>
            </div>
            <div className='experience-card'>
              <i>
                <img
                  src={
                    require(`../assets/img/home/welcome/font-icon/home-blue.svg`)
                      .default
                  }
                  alt='home'
                />
              </i>
              <h3 className='experience-card__title'>Expert Support</h3>
              <p className='experience-card__desc'>
                Enim odio ut nulla malesuada eu. Vel donec porta praesent
                libero. Lobortis eu pellentesque viverra fringilla. Gravida
                congue integer ultrices nullam nisi in elit vel vitae.
              </p>
            </div>
            <div className='experience-card'>
              <i>
                <img
                  src={
                    require(`../assets/img/home/welcome/font-icon/home-blue.svg`)
                      .default
                  }
                  alt='home'
                />
              </i>
              <h3 className='experience-card__title'>Quality Properties</h3>
              <p className='experience-card__desc'>
                Enim odio ut nulla malesuada eu. Vel donec porta praesent
                libero. Lobortis eu pellentesque viverra fringilla. Gravida
                congue integer ultrices nullam nisi in elit vel vitae.
              </p>
            </div>
            <div className='experience-card_space'></div>
          </div>
        </div>
      </section>
      <section className='reservation'>
        <div className='reservation__container'>
          <div className='reservation-heading'>
            <h4 className='reservation-heading__header'>reservation process</h4>
            <h2 className='reservation-heading__title'>
              fast, intuitive and absolutely safe !
            </h2>
          </div>
          <div className='reservation-cards'>
            <div className='reservation-card'>
              <article>
                <span className='reservation-card__num'>1</span>
                <h5 className='reservation-card__title'>Pick a few places</h5>
                <p className='reservation-card__desc'>
                  Explore hundreds of high-quality rooms, studios, and
                  apartments. Save favorites and book it. Finding your dream
                  home could not be easier!
                </p>
              </article>
            </div>
            <div className='reservation-card'>
              <article>
                <span className='reservation-card__num'>2</span>
                <h5 className='reservation-card__title'>
                  Accepting a reservation
                </h5>
                <p className='reservation-card__desc'>
                  You will receive the acceptance of the reservation from the
                  owner in just a couple of hours. You will not have to wait
                  long for an answer and torment yourself with guesses.
                </p>
              </article>
            </div>
            <div className='reservation-card'>
              <article>
                <span className='reservation-card__num'>3</span>
                <h5 className='reservation-card__title'>Payment</h5>
                <p className='reservation-card__desc'>
                  All that is necessary after receiving a response, is to send
                  the payment and you are almost at the finish line!
                </p>
              </article>
            </div>
            <div className='reservation-card'>
              <article>
                <span className='reservation-card__num'>4</span>
                <h5 className='reservation-card__title'>Get your keys!</h5>
                <p className='reservation-card__desc'>
                  {' '}
                  Your accommodation is reserved, get ready to move as soon as
                  possible and check in on your chosen date.
                </p>
              </article>
            </div>
          </div>
        </div>
      </section>
      <section className='questions' id='questions'>
        <div className='questions__container'>
          <div className='questions-wrapper'>
            <div className='questions-heading'>
              <h3 className='questions-heading__title'>
                Have questions or doubts?
              </h3>
              <p className='questions-heading__desc'>
                don't hesitate contact us
              </p>
            </div>
            <div className='questions-contact'>
              <form action='#' className='questions-contact__form'>
                <input
                  className='questions-filter-form__item'
                  type='text'
                  name='name'
                  placeholder='Full Name'
                />
                <input
                  className='questions-filter-form__item'
                  type='text'
                  name='question'
                  placeholder='Enter your Question...'
                />
                <button type='submit' className='__btn'>
                  Send
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export { HomePage }
