import React from 'react'

function NotfoundPage() {
  return (
    <>
      <div>
        <div className='container'>
          <div>NotfoundPage</div>
        </div>
      </div>
    </>
  )
}

export { NotfoundPage }
