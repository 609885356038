import React from "react";
import { NavLink } from 'react-router-dom'
function LoginPage() {
  return (
    <>
      <section className="login">
				<div className="login__container">
					<div className="login__wrapper login-img login-info">
						<div className="login-img-block">
							<img className="login-img-block__item" src={require(`../assets/img/auth/login-bg.png`)}  alt="login"/>
						</div>
						<div className="login-info-block">
							<div className="login-info-block__item block-item">
								<h1 className="block-item__title">Log in</h1>
								<p className="block-item__desc">Welcome back! Please enter your details.</p>
							</div>
							<div className="login-info-block__form block-form">
								<form action="#" className="block-form-wrap">
									<div className="block-form-wrap__item">
										<label htmlFor="email">Email</label>
										<input type="email" name="email" placeholder="Enter your email" required/>
									</div>
									<div className="block-form-wrap__item">
										<label htmlFor="password">Password</label>
										<input type="password" name="password" placeholder="Enter your password" required/>
									</div>
									<div className="block-form-wrap__action">
										<div className="checkbox">
											<input id="c_1" className="checkbox__input" type="checkbox" name="remember"/>
											<label htmlFor="c_1" className="checkbox__label"><span className="checkbox__text">Remember for 30 days</span></label>
										</div>
										<a href="#">Forgot password?</a>
									</div>
									<button className="block-form-wrap__btn" type="submit">Sign in</button>
								</form>
							</div>
							<div className="login-info-block__reg block-reg">
								<p className="block-reg__desc">Don’t have an account?</p>
								<NavLink className="block-reg__link" to="/registration">Sign up</NavLink>
							</div>
						</div>
					</div>
				</div>
			</section>
    </>
 
  )
}

export  {LoginPage}