import React, { useState, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
/**
 * Here I have used the following approach for svg, so that it is possible to edit them
 */
import { ReactComponent as Logo } from '../assets/img/header/logo.svg'

function Header() {
  const [isActiveMenuBtn, setActiveMenuBtn] = useState(false)

  /**
   * Switch for hamburger menu
   */
  const handleToggleMenuBtn = () => {
    setActiveMenuBtn(!isActiveMenuBtn)
    document.body.classList.toggle('hiden')
  }

  return (
    <>
      <header className='header'>
        <div className='header__container'>
          <div className='header__wrapper'>
            <div className='header__logo'>
              <a href='/'>
                <Logo />
              </a>
            </div>
            <nav
              className={`header__inner-nav ${isActiveMenuBtn ? 'active' : ''}`}
            >
              <ul className='header__nav-block link nav-list'>
                <li>
                  <NavLink to='/'>Home</NavLink>
                </li>
                <li>
                  <HashLink to='/#questions'>Contact</HashLink>
                </li>
                <li>
                  <NavLink to='/privacy-policy'>Privacy Policy</NavLink>
                </li>
                <li>
                  <NavLink to='/terms'>Terms</NavLink>
                </li>
              </ul>
              <div className='header__action'>
                <div className='header__action-auth'>
                  <NavLink to='/login'>Log In</NavLink>
                </div>
                <div className='header__action-link'>
                  <NavLink to='/rooms'>Rent a Room</NavLink>
                </div>
              </div>
            </nav>

            <div
              className={`header__menu-btn ${isActiveMenuBtn ? 'open' : ''}`}
              onClick={handleToggleMenuBtn}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export { Header }
